import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';

import ArtCard from '../Card/ArtCard';

const TITLE = 'processed (art): manifest | gallery'

function Gallery({ classes, allManifests }) {
  return (
    <div className={classes.paperGallery}>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <Grid container style={{ padding: '0 6%'}}>
        {allManifests.slice(0).reverse().map((el) => (
          <Grid item xl={3} lg={4} md={4} sm={6} xs={12} style={{ padding: '1%' }} key={el.tokenId}>
            <div className={classes.galleryArt}>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align='center'>
                  <ArtCard data={el} />
                </Grid>
              </Grid>
            </div>
          </Grid>
        ))}
      </Grid>
      <Typography className={classes.footer} variant='overline'>
        <Link className={classes.linkHide} to='/'>
          home
        </Link>
      </Typography>
    </div>
  );
}

export default React.memo(Gallery);
