import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';

import MirageCard from '../Card/MirageCard';

const TITLE = 'processed (art): manifest | process'

const currencies = [
    {
        value: '0',
        label: 'Ξ Free',
    }
];

function ProcessPanel({ classes, loadWeb3, processManifest, allManifests, ownerMirages, currency, setCurrency, mirage, setMirage }) {
    const [hasntManifested, setHasntManifested] = useState([]);
    useEffect(() => {
        (async () => {
            loadWeb3();
        })();
    }, [loadWeb3]);
    useEffect(() => {
        (async () => {
            setHasntManifested(ownerMirages.filter(a => !allManifests.some(manifest => a.attributes[0].value === manifest.attributes[1].value)));
        })();
        }, [allManifests, ownerMirages]);

    const handleChange = (event) => {
        setCurrency(event.target.value);
    };
    
  return (
    <div className={classes.paperManifest}>
    <Helmet>
      <title>{ TITLE }</title>
    </Helmet>
    <Grid container>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
        <Grid container>
        {hasntManifested.map((el) => (
            <Grid className={mirage === el.tokenId && classes.selectedMirage} item xl={2} lg={3} md={4} sm={4} xs={4} style={{ padding: '2%' }} key={el.tokenId} onClick={() => setMirage(el.tokenId)}> 
                <MirageCard data={el} />
            </Grid>
        ))}
        </Grid>
        </Grid>
        <Grid item xl={2} lg={3} md={3} sm={12} xs={12}>
            <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '100%', marginTop: '5ch', marginBottom: '2ch' },
            }}
            noValidate
            autoComplete="off"
            >
            <div style={{ margin: '0 10px 100px 10px'}}>
                <form className={classes.form} noValidate>
                <TextField
                    id="payDropdown"
                    select
                    label="DONATION"
                    value={currency}
                    onChange={handleChange}
                    SelectProps={{
                        native: true,
                    }}
                >
                {currencies.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
                </TextField>
                <Button
                id="processButton"
                variant='outlined'
                color='default'
                fullWidth
                onClick={processManifest}
                >{`process manifest`}
                </Button>
                </form>
            </div>
            </Box>
        </Grid>
    </Grid>
    <Typography className={classes.footer} variant='overline'>
        <Link className={classes.linkHide} to='/'>
          home
        </Link>
      </Typography>
    </div>
  );
}

export default React.memo(ProcessPanel);