import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { manifestContractAddress } from '../../config';
import { Helmet } from 'react-helmet';

const TITLE = 'processed (art): manifest'

function Home({classes, loadWeb3}) {
  return (
    <div className={classes.paper}>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <Grid container className={classes.pieceDetailsGrid}>
        <Grid container item xs={12} sm={12} md={12}>
          <Typography className={[classes.wording, classes.definition].join(' ')} variant='overline'>
            <b>manifest</b>:{' '}
            <br />
            <i>
              verb: to become apparent; to demonstrate; appear.<br />
              adjective: clear or obvious to the mind or eye.<br />
              noun: a document giving comprehensive details of cargo.<br />
              Origin: latin 'manifestus', meaning "palpable, handled, tangible".<br />
            </i>
            <Link
              to='/process'
              className={[classes.linkHide, classes.underlineLink, classes.processButton].join(' ')}
              rel='noreferrer'
              onClick={loadWeb3}
            >
            Process Manifest
            </Link>
          </Typography>
          <Typography className={classes.wordingTop} variant='overline'>
            Mainnet Verified Contract -{' '}
            <a
              className={classes.linkHide}
              target='_blank'
              href={'https://etherscan.io/address/' + manifestContractAddress}
              rel='noreferrer'
            >
              {manifestContractAddress}
            </a>
            <br />
            <a
              className={classes.linkHide}
              target='_blank'
              href='https://opensea.io/collection/processed-art-manifest'
              rel='noreferrer'
            >
              opensea
            </a>
            &nbsp;|&nbsp;
            <a
              className={classes.linkHide}
              target='_blank'
              href='https://discord.gg/8c9TRNqqmx'
              rel='noreferrer'
            >
              discord
            </a>
            &nbsp;|&nbsp;
            <a
              className={classes.linkHide}
              target='_blank'
              href='https://twitter.com/processedart'
              rel='noreferrer'
            >
              twitter
            </a>
            &nbsp;|&nbsp;
            <a
              className={classes.linkHide}
              target='_blank'
              href='https://instagram.com/processed.art'
              rel='noreferrer'
            >
              instagram
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Typography className={classes.footer} variant='overline'>
        <Link className={classes.linkHide} to='/gallery'>
          gallery
        </Link>
      </Typography>
    </div>
  );
}

export default React.memo(Home);
