import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';
import axios from 'axios';
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import mirageAbi from './abi/ProcessedArtMirage.json';
import manifestAbi from './abi/ProcessedArtManifest.json';
import { manifestContractAddress, mirageContractAddress, serverUrl, ipfsGateway, ALCHEMY_URL } from './config';

import Home from './components/Home/Home';
import Gallery from './components/Gallery/Gallery';
import Manifest from './components/Manifest/Manifest';
import ProcessPanel from './components/processPanel/processPanel';
import { minHeight } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  manifest: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
  },
  paper: {
    margin: theme.spacing(4, 8),
    '@media (max-width: 780px)' : { 
      paddingBottom: "300px",
      margin: theme.spacing(6, 5),
    },
  },
  paperGallery: {
    padding: theme.spacing(4, 0),
    '@media (max-width: 780px)' : { 
      margin: theme.spacing(0, 2),
    },
  },
  paperManifest: {
    margin: theme.spacing(4, 2),
  },
  processButton: {
    margin: '20px 0',
    display: 'block'
  },
  underlineLink: {
    textDecoration: 'underline !important',
    '&:hover': {
      textDecoration: 'none !important'
    },
  },
  linkHide: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: '#8100ec',
      cursor: 'pointer'
    },
  },
  subtext: {
    fontSize: '15px',
    margin: '10px 10px',
    textAlign: 'center'
  },
  pieceDetailsGrid: {
    width: '100%',
  },
  pieceDetails: {
    fontSize: '18px',
  },
  pieceDetailsCount: {
    float: 'right',
    marginRight: '20px',
  },
  wording: {
    float: 'left',
    width: '100%'
  },
  definition: {
    display: 'block',
    lineHeight: '22px !important',
  },
  wordingTop: {
    marginTop: '10px',
  },
  galleryArt: {
    padding: '10px',
    width: '100%',
  },
  fadeOut: {
    opacity: '0',
    transition: 'opacity .5s',
  },
  fadeIn: {
    opacity: '1',
    transition: 'opacity 1s 1s',
  },
  footer: {
    position: 'fixed',
    bottom: '10px',
    right: '15px',
  },
  selectedMirage: {
    background: "#666",
  },
}));

let mirageContract;
let manifestContract;
let web3;

const App = () => {
  const history = useHistory();
  const classes = useStyles();
  const [account, setAccount] = useState('');
  const [ownerMirages, setOwnerMirages] = useState([]);
  const [ownerManifests, setOwnerManifests] = useState([]);
  const [imgSrc, setImgSrc] = useState('');
  const [manifestVisible, setManifestVisible] = useState(false);
  const [allManifests, setAllManifests] = useState([]);
  const [allMirages, setAllMirages] = useState([]);
  const [activeManifest, setActiveManifest] = useState(null);
  const [currency, setCurrency] = React.useState('0');
  const [mirage, setMirage] = React.useState(null);
  
  const processManifest = useCallback(async () => {
    if (window.ethereum) {
      try {
        window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(async (accounts) => {
          web3 = createAlchemyWeb3(ALCHEMY_URL);
          manifestContract = new web3.eth.Contract(manifestAbi, manifestContractAddress);
          setAccount(accounts[0]);
          if(mirage && currency) {
          const mint = await manifestContract.methods.processManifest(mirage).send({ from: account, value: web3.utils.toWei(currency) });
          const newManifest = await axios.get(
            serverUrl + `manifest/${mint.events.Transfer.returnValues.tokenId}`
          );
          setActiveManifest(newManifest.data);
          } else if(!mirage) {
            alert('Select your mirage before attempting to process manifest')
          }
        })
        .catch(console.log());
      } catch (e) {
        console.log(e)
      }
    } 
  }, [account, currency, mirage]);

  const setMiragePanel = useCallback(async (activeManifest) => {
    if (activeManifest) {
      setManifestVisible(false);
      setTimeout(function () {
        setImgSrc(`${ipfsGateway}/ipfs/${activeManifest.web_cid}`);
        setManifestVisible(true);
      }, 500);
    } 
  }, []);

  const loadWeb3 = useCallback(async (history) => {
    if (window.ethereum) {
      try {
        window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(async (accounts) => {
          web3 = createAlchemyWeb3(ALCHEMY_URL);
          manifestContract = new web3.eth.Contract(manifestAbi, manifestContractAddress);
          mirageContract = new web3.eth.Contract(mirageAbi, mirageContractAddress);
          setAccount(accounts[0]);
          let ownersMirageTokens = await mirageContract.methods.tokensOfOwner(accounts[0]).call();
          let promisedMirages = ownersMirageTokens.map(async (token) => await (await axios.get(serverUrl + `mirage/${token}`)).data);
          const mirages = await Promise.all(promisedMirages)
          setOwnerMirages(mirages);
          let ownersManifestTokens = await manifestContract.methods.tokensOfOwner(accounts[0]).call();
          let promisedManifests = ownersManifestTokens.map(async (token) => await (await axios.get(serverUrl + `manifest/${token}`)).data);
          const manifests = await Promise.all(promisedManifests)
          setOwnerManifests(manifests);
        })
        .catch();
      } catch (e) {
        alert("Couldn't get accounts")
      } 
    } else {
      history.push('/');
      alert("Oh no, you'll need an ethereum connection to proceed.")
    }
  }
  , []);

  const loadManifests = useCallback(async () => {
    let allManifests = await axios.get(serverUrl + 'manifest/all');
    setAllManifests(Object.values(allManifests.data));
  }, []);

  const loadMirages = useCallback(async () => {
    let allMirages = await axios.get(serverUrl + 'mirage/all');
    setAllMirages(Object.values(allMirages.data));
  }, []);

  useEffect(() => {
    (async () => {
      await loadManifests();
      await loadMirages();
    })();
  }, [loadManifests, loadMirages]);

  useEffect(() => {
    setMiragePanel(activeManifest);
  }, [activeManifest, setMiragePanel]);

  useEffect(() => {
    let manifestId = `${Math.ceil(Math.random() * 120 + 1)}`;
    if (!activeManifest) {
      const manifest = allManifests.find((manifest) => manifest.tokenId === manifestId);
      setActiveManifest(manifest ?? null);
    }
  }, [setActiveManifest, allManifests, activeManifest]);

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <img
          src={imgSrc}
          className={[
            classes.manifest,
            manifestVisible ? classes.fadeIn : classes.fadeOut,
          ].join(' ')}
          alt='processed (art): manifest'
        />
      <Grid item xs={12} sm={12} md={12} component={Paper} elevation={5} square>
        <Router>
          <Switch>
            <Route path='/gallery'>
              <Gallery
                classes={classes}
                allManifests={allManifests}
                setActiveManifest={setActiveManifest}
              />
            </Route>
            <Route path='/manifest/:manifestId'>
              <Manifest
                classes={classes}
                setActiveManifest={setActiveManifest}
                allManifests={allManifests}
                activeManifest={activeManifest}
                ownerManifests={ownerManifests}
                allMirages={allMirages}
              />
            </Route>
            <Route path='/process'>
              <ProcessPanel
                classes={classes}
                ownerMirages = {ownerMirages}
                allManifests={allManifests}
                setActiveManifest={setActiveManifest}
                loadWeb3={loadWeb3}
                currency={currency}
                setCurrency={setCurrency}
                mirage={mirage}
                setMirage={setMirage}
                processManifest={processManifest}
              />
            </Route>
            <Route path='/'>
              <Home
                classes={classes}
                setActiveManifest={setActiveManifest}
                setAccount={setAccount}
                loadWeb3={loadWeb3}
                activeManifest={activeManifest}
              />
            </Route>
          </Switch>
        </Router>
      </Grid>
    </Grid>
  );
}

export default App;
