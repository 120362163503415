import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { manifestContractAddress, serverUrl, ipfsGateway } from '../../config';

function Manifest({ classes, setActiveManifest, allManifests, activeManifest, allMirages }) {
  const { manifestId } = useParams();
  const [mirageManifested, setMirageManifested] = useState({});
  const TITLE = `processed (art): manifest | manifest #${manifestId}`
  useEffect(() => {
    (async () => {
      let manifest = allManifests.find((manifest) => manifest.tokenId === manifestId);
      setActiveManifest(manifest ?? null);
      setMirageManifested(allMirages.filter(a => a.attributes[0].value === activeManifest.attributes[1].value))
    })();
  }, [setActiveManifest, manifestId, allManifests, activeManifest, allMirages, mirageManifested]);
  return activeManifest ? (
    <div className={classes.paperManifest}>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <Grid container style={{ padding: '0 0 5% 10%' }}>
      <Typography
        component='h1'
        variant='overline'
        style={{ fontSize: '32px', display: 'block', width: "100%" }}
      >
        manifest #{activeManifest.tokenId}
      </Typography>
      <Grid item xl={5} lg={5} md={5} sm={12} xs={12} style={{ wordWrap: 'break-word' }}>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          {activeManifest.attributes[2].trait_type}
        </Typography>
        <Typography component='h6' variant='overline'>
          {activeManifest.attributes[2].value}
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          {activeManifest.attributes[3].trait_type}
        </Typography>
        <Typography component='h6' variant='overline'>
          {activeManifest.attributes[3].value}
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          {activeManifest.attributes[4].trait_type}
        </Typography>
        <Typography component='h6' variant='overline'>
          {activeManifest.attributes[4].value}
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          {activeManifest.attributes[5].trait_type}
        </Typography>
        <Typography component='h6' variant='overline'>
          {activeManifest.attributes[5].value}
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          {activeManifest.attributes[6].trait_type}
        </Typography>
        <Typography component='h6' variant='overline'>
          {activeManifest.attributes[6].value}
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          {activeManifest.attributes[8].trait_type}
        </Typography>
        <Typography component='h6' variant='overline'>
          {activeManifest.attributes[8].value.toString()}
        </Typography>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{wordWrap: 'break-word' }}>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          {activeManifest.attributes[9].trait_type}
        </Typography>
        <Typography component='h6' variant='overline'>
          {new Date(activeManifest.attributes[9].value).toLocaleString()}
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          license
        </Typography>
        <Typography component='h6' variant='overline'>
          <a
            className={classes.linkHide}
            href='https://www.niftylicense.org/'
          >
            {activeManifest.license}
          </a>
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          token hash
        </Typography>
        <Typography component='h6' variant='overline'>
          <a
            className={classes.linkHide}
            target='_blank'
            href={`https://etherscan.io/token/${manifestContractAddress}?a=${activeManifest.tokenId}`}
            rel='noreferrer'
          >
            {activeManifest.attributes[0].value}
          </a>
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          mirage hash
        </Typography>
        <Typography component='h6' variant='overline'>
          <a
            className={classes.linkHide}
            target='_blank'
            href={mirageManifested[0] ? mirageManifested[0].external_url : '#'}
            rel='noreferrer'
          >
            {activeManifest.attributes[1].value}
            </a>
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          sizes
        </Typography>
        <Typography component='h6' variant='overline'>
          <a
            className={classes.linkHide}
            target='_blank'
            href={`${activeManifest.small}`}
            rel='noreferrer'
          >
            small
          </a>
          &nbsp; | &nbsp;
          <a
            className={classes.linkHide}
            target='_blank'
            href={`${activeManifest.mid}`}
            rel='noreferrer'
          >
            medium
          </a>
          &nbsp; | &nbsp;
          <a
            className={classes.linkHide}
            target='_blank'
            href={`${ipfsGateway}/ipfs/${activeManifest.web_cid}`}
            rel='noreferrer'
          >
            high
          </a>
          &nbsp; | &nbsp;
          <a
            className={classes.linkHide}
            target='_blank'
            href={`${ipfsGateway}/ipfs/${activeManifest.cid}`}
            rel='noreferrer'
          >
            ultra
          </a>
        </Typography>
        <Typography
          component='h6'
          variant='overline'
          style={{ fontSize: '16px' }}
        >
          links
        </Typography>
        <Typography component='h6' variant='overline'>
          <a
            className={classes.linkHide}
            target='_blank'
            href={`https://opensea.io/assets/${manifestContractAddress}/${activeManifest.tokenId}`}
            rel='noreferrer'
          >
            opensea
          </a>
          &nbsp; | &nbsp;
          <a
            className={classes.linkHide}
            target='_blank'
            href={`${serverUrl}generate/manifest/${activeManifest.tokenId}`}
            rel='noreferrer'
          >
            script
          </a>
          &nbsp; | &nbsp;
          <a
            className={classes.linkHide}
            target='_blank'
            href={`${activeManifest.interactive_nft.ipfs_uri}`}
            rel='noreferrer'
          >
            IPFS
          </a>
          &nbsp; | &nbsp;
          <a
            className={classes.linkHide}
            target='_blank'
            href={`${activeManifest.interactive_nft.arweave_uri}`}
            rel='noreferrer'
          >
            arweave
          </a>
        </Typography>
      </Grid>
    </Grid>
    <Typography className={classes.footer} variant='overline'>
      <Link className={classes.linkHide} to='/'>
        home
      </Link>
      &nbsp; | &nbsp;
      <Link className={classes.linkHide} to='/gallery'>
        gallery
      </Link>
    </Typography>
  </div>
  ) : null;
}

export default React.memo(Manifest);
