import { Paper } from '@material-ui/core'
import React from 'react'
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { makeStyles } from '@material-ui/core/styles';
import { ipfsGateway } from "../../config";
import 'react-lazy-load-image-component/src/effects/blur.css';

const useStyles = makeStyles({
    imageElement: {
        width: "100%",
        padding: "0",
        display: "block",
        marginBottom: "-10px",
    },
    linkElement: {
        width: "auto",
        padding: "0",
        display: "block",
        marginBottom: "-10px",
        '&>span': {
            width: "100%"
        },
    }
  });

export default function ArtCard({data}) {
    const classes = useStyles();
    return (
    <Paper elevation={5}>
        <Link to={"/manifest/"+ data.tokenId} className={classes.linkElement}>
            <LazyLoadImage
            alt="processed (art): manifest"
            className={classes.imageElement}
            effect="opacity"
            src={ipfsGateway + '/ipfs/' + data.image.replace('ipfs://','')} />
        </Link>
    </Paper>
    )
}
